import Vue from "vue";
import Router from "vue-router";
import Swal from "sweetalert2";
const Drivers = (resolve) =>
  require.ensure([], () =>
    resolve(require("@/components/Drivers/Drivers.vue"))
  );
import WaybillDetailOld from "@/components/Waybill/WaybillDetail.vue";
import WaybillDetail from "@/components/Waybill/WaybillDetailNew.vue";
import QrCode from "@/components/QrCode/QrCode.vue";
import Redirect from "@/components/Redirect/Redirect.vue";
import PageNotFound from "@/components/PageNotFound/PageNotFound";

import { StorageKeys } from "@/constants/constants.js";
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/jobs",
    },
    {
      path: "*",
      component: PageNotFound,
    },
    {
      path: "/auth",
      name: "auth",
      component: () =>
        import(/* webpackChunkName: "auth" */ "@/components/Auth/Auth.vue"),
    },
    {
      path: "/dashboard",
      name: "dashboard",

      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "@/components/DashBoard/DashBoard"
        ),
    },
    {
      path: "/transporter",
      name: "transporter",
      component: () =>
        import(
          /* webpackChunkName: "transporter" */ "@/components/Transporter/transporter.vue"
        ),
      meta: { permission: "transporter", role: true },
    },
    {
      path: "/shipment-documents",
      name: "shipmentDocument",
      component: () =>
        import(
          /* webpackChunkName: "shipment-documents" */ "@/components/shipmentDocument/shipmentDocument"
        ),

      meta: { permission: "shipment-documents", role: true },
    },
    {
      path: "/documents/:_id",
      name: "documents",
      component: () =>
        import(
          /* webpackChunkName: "documents" */ "@/components/JobDocuments/documents"
        ),
      meta: { permission: "shipment-documents", role: true },
    },

    {
      path: "/gps-devices",
      name: "ImeiDevices",
      component: () =>
        import(
          /* webpackChunkName: "gps-device" */ "@/components/ImeiDevices/ImeiDevicesList.vue"
        ),
      meta: { permission: "gps-devices", role: true },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () =>
        import(
          /* webpackChunkName: "notifications" */ "@/components/Notifications/Notification.vue"
        ),
    },
    {
      path: "/approvals",
      name: "Approvals",
      component: () =>
        import(
          /* webpackChunkName: "approvals" */ "@/components/Approvals/Approvals"
        ),
      meta: { permission: "approvals", role: true },
    },
    {
      path: "/tender/:type",
      name: "tender",
      component: () =>
        import(/* webpackChunkName: "tender" */ "@/components/Tender/tender"),
      meta: { permission: "tender", role: true },
    },
    {
      path: "/analytics",
      name: "analytics",
      component: () =>
        import(
          /* webpackChunkName: "analytics" */ "@/components/Analytics/analytics"
        ),
      // meta: { permission: "analytics", role: true },
      // meta: { permission: "customers", role: true },
    },
    {
      path: "/driverfilters/:id/:tabOrder/:jobType",
      name: "driverfilters",
      component: () =>
        import(
          /* webpackChunkName: "driverfilters"" */ "@/components/driverfilters/driverfilters"
        ),
    },
    {
      path: "/driverfilters/:id/:tabOrder",
      name: "driverfilters",
      component: () =>
        import(
          /* webpackChunkName: "driverfilters"" */ "@/components/driverfilters/driverfilters"
        ),
    },
    {
      path: "/driverfilters/:id",
      name: "driverfilters",
      component: () =>
        import(
          /* webpackChunkName: "driverfilters" */ "@/components/driverfilters/driverfilters"
        ),
    },
    {
      path: "/accounts",
      name: "Accounts",
      component: () =>
        import(
          /* webpackChunkName: "accounts" */ "@/components/Accounts/Accounts.vue"
        ),
      meta: { permission: "accounts", role: true },
    },
    {
      path: "/accounts/advance",
      name: "Advance",
      component: () =>
        import(
          /* webpackChunkName: "accounts" */ "@/components/Accounts/AdvanceRequestList/Advance.vue"
        ),
      meta: { permission: "accounts", role: true },
    },
    {
      path: "/accounts/customerPayment",
      name: "customerPayment",
      component: () =>
        import(
          /* webpackChunkName: "accounts" */ "@/components/Accounts/CustomerPayment/CustomerPaymentList.vue"
        ),
      meta: { permission: "accounts", role: true },
    },

    {
      path: "/transporter/new",
      name: "transporter-profile-new",
      component: () =>
        import(
          /* webpackChunkName: "transporter-profile-new" */ "@/components/Transporter/transporterProfileform"
        ),
    },
    {
      path: "/transporter/driver-edit/:transporterId/:_id/:id/:country",
      name: "transporter-driver-detail",
      component: () =>
        import(
          /* webpackChunkName: "transporter-driver-detail" */ "@/components/Transporter/EditDriver"
        ),
      children: [
        {
          path: "/transporter/driver-edit/:transporterId/:_id/:id/:country/profile",
          name: "transporter-driver-detail-profile",
          component: () =>
            import(
              /* webpackChunkName: "transporter-driver-detail-profile" */ "@/components/Transporter/TransporterDriverProfile"
            ),
        },
        {
          path: "/transporter/driver-edit/:transporterId/:_id/:id/:country/assignments",
          name: "transporter-driver-detail-assignments",
          component: () =>
            import(
              /* webpackChunkName: "transporter-driver-detail-assignments" */ "@/components/Transporter/TransporterDriverDetailAssignments"
            ),
        },
        {
          path: "/transporter/driver-edit/:transporterId/:_id/:id/:country/earnings",
          name: "transporter-driver-detail-earnings",
          component: () =>
            import(
              /* webpackChunkName: "transporter-driver-detail-earnings" */ "@/components/Transporter/TransporterDriverDetailEarning"
            ),
        },
      ],
    },
    {
      path: "/transporter/add/:id/:country",
      name: "transporter-driver-profile-new",
      component: () =>
        import(
          /* webpackChunkName: "transporter-driver-profile-new" */ "@/components/Transporter/AddDriver"
        ),
    },
    {
      path: "/transporter/edit/:id/:_id/:country",
      name: "transporter-detail",
      component: () =>
        import(
          /* webpackChunkName: "transporter-detail" */ "@/components/Transporter/TransporterDetail"
        ),
    },
    {
      path: "/transporter/driver/:id/:_id/:country",
      name: "transporter-driver-detail",
      component: () =>
        import(
          /* webpackChunkName: "transporter-driver-detail" */ "@/components/Transporter/TransporterDriverDetail"
        ),
    },

    {
      path: "/drivers",
      name: "drivers",
      component: () =>
        import(
          /* webpackChunkName: "drivers" */ "@/components/Drivers/Drivers.vue"
        ),
      meta: { permission: "drivers", role: true },
    },
    {
      path: "/jobs",
      name: "jobs",
      component: () =>
        import(/* webpackChunkName: "jobs" */ "@/components/Jobs/Jobs.vue"),
      meta: { permission: "jobs", role: true },
    },
    {
      path: "/assignments",
      name: "assignments",
      component: () =>
        import(
          /* webpackChunkName: "procurement" */ "@/components/Assignments/Assignments.vue"
        ),
      // meta: { permission: "procurements", role: true },
    },
    {
      path: "/procurements",
      name: "procurement",
      component: () =>
        import(
          /* webpackChunkName: "procurement" */ "@/components/Procurement/Procurement.vue"
        ),
      meta: { permission: "procurements", role: true },
    },
    {
      path: "/procurement/new",
      name: "jobs-profile-new",
      component: () =>
        import(
          /* webpackChunkName: "jobs-booking-new" */ "@/components/Procurement/JobsProfileNew"
        ),
    },
    {
      path: "/procurement/new/:id",
      name: "jobs-booking-new",
      component: () =>
        import(
          /* webpackChunkName: "jobs-booking-new" */ "@/components/Procurement/Procurement.vue"
        ),
    },

    {
      path: "/jobs/:id",
      name: "jobs-profile",
      component: () =>
        import(
          /* webpackChunkName: "jobs-profile" */ "@/components/Jobs/JobDetails/JobsProfile.vue"
        ),
    },

    {
      path: "/jobs/:id/:viewOnly",
      name: "jobs-profile-view",
      component: () =>
        import(
          /* webpackChunkName: "jobs-profile-view" */ "@/components/Jobs/JobDetails/JobsProfile.vue"
        ),
    },
    {
      path: "/drivers/new",
      name: "driver-profile-new",
      component: () =>
        import(
          /* webpackChunkName: "drivers-profile-new" */ "@/components/Drivers/DriverProfileForm"
        ),
    },
    {
      path: "/drivers/:id",
      name: "driver-detail",
      component: () =>
        import(
          /* webpackChunkName: "driver-detail" */ "@/components/Drivers/DriverDetail"
        ),
      children: [
        {
          path: "/drivers/:id/profile",
          name: "driver-detail-profile",
          component: () =>
            import(
              /* webpackChunkName: "driver-detail"-profile" */ "@/components/Drivers/DriverDetailProfile"
            ),
        },
        {
          path: "/drivers/:id/assignments",
          name: "driver-detail-assignments",
          component: () =>
            import(
              /* webpackChunkName: "driver-detail"-assignments" */ "@/components/Drivers/DriverDetailAssignments"
            ),
        },
      ],
    },
    {
      path: "/assignments/:id/:type/waybill",
      name: "waybill-detail",
      component: WaybillDetail,
    },
    {
      path: "/operations/:id/inquiry",
      name: "opeartion-sales",
      component: Redirect,
    },
    {
      path: "/invoice-details",
      name: "invoice-detail",
      component: QrCode,
    },

    {
      path: "/assignments-old/:id/:type/waybill",
      name: "waybill-detail-old",
      component: WaybillDetailOld,
    },
    {
      path: "/asset/:id/asset-form",
      name: "AssetForm",
      component: () =>
        import(
          /* webpackChunkName: "assetForm" */ "@/components/Documents/AssetForm.vue"
        ),
    },
    {
      path: "/driver/:id/driver-form",
      name: "DriverForm",
      component: () =>
        import(
          /* webpackChunkName: "DriverForm" */ "@/components/Documents/DriverInfoForm.vue"
        ),
    },
    {
      path: "/request-callback",
      name: "request-callback",
      component: () =>
        import(
          /* webpackChunkName: "request-callback" */ "@/components/Request-Callback/RequestCallback"
        ),
      meta: { permission: "request-callback", role: true },
    },
    {
      path: "/customers",
      name: "customer-section",
      component: () =>
        import(
          /* webpackChunkName: "customer-section" */ "@/components/Customer/Customer"
        ),
      meta: { permission: "customers", role: true },
    },
    {
      path: "/customer/details/:id/:userId",
      name: "customer-details",
      component: () =>
        import(
          /* webpackChunkName: "customer-details" */ "@/components/Customer/CustomerDetails"
        ),
    },

    {
      path: "/customer/new",
      name: "customer-registration",
      component: () =>
        import(
          /* webpackChunkName: "customer-registration" */ "@/components/Customer/CustomerRegistration"
        ),
    },
    {
      path: "/customer/edit/:id",
      name: "customer-edit",
      component: () =>
        import(
          /* webpackChunkName: "customer-edit" */ "@/components/Customer/EditCustomer"
        ),
    },
    {
      path: "/sales",
      name: "sales-section",
      component: () =>
        import(
          /* webpackChunkName: "sales-section" */ "@/components/Sales/Sales"
        ),

      meta: { permission: "sales", role: true },
    },
    {
      path: "/inquiry/new",
      name: "add-enquiry",
      component: () =>
        import(
          /* webpackChunkName: "add-enquiry" */ "@/components/Sales/AddEnquiry"
        ),
    },
    {
      path: "/inquiry/edit/:id",
      name: "edit-enquiry",
      component: () =>
        import(
          /* webpackChunkName: "edit-enquiry" */ "@/components/Sales/EditEnquiry"
        ),
    },
    {
      path: "/inquiry/recreate/:id",
      name: "re-create-enquiry",
      component: () =>
        import(
          /* webpackChunkName: "re-create-enquiry" */ "@/components/Sales/ReCreateEnquiry"
        ),
    },
    // {
    //   path: "/pay-advance/:id",
    //   name: "pay-advance",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "pay-advance" */ "@/components/Finance/PayAdvance"
    //     ),
    // },
    {
      path: "/additionalCharge",
      name: "list-additionalCharge",
      component: () =>
        import(
          /* webpackChunkName: "list-milestone" */ "@/components/AdditionalCharges/additionalChargeList"
        ),
      // meta: { permission: "milestone", role: true },
    },
    {
      path: "/add-new-additionalCharge",
      name: "add-additional-charge",
      component: () =>
        import(
          /* webpackChunkName: "add-milestone" */ "@/components/AdditionalCharges/addAdditionalForm"
        ),
      // meta: { permission: "add-milestone", role: true }
    },
    {
      path: "/edit-additionalCharge/:sourceId",
      name: "add-additional-charge",
      component: () =>
        import(
          /* webpackChunkName: "add-milestone" */ "@/components/AdditionalCharges/editAdditionalForm"
        ),
      // meta: { permission: "add-milestone", role: true }
    },
    {
      path: "/milestone",
      name: "list-milestone",
      component: () =>
        import(
          /* webpackChunkName: "list-milestone" */ "@/components/Milestone/Milestone"
        ),
      meta: { permission: "milestone", role: true },
    },
    {
      path: "/add-milestone",
      name: "add-milestone",
      component: () =>
        import(
          /* webpackChunkName: "add-milestone" */ "@/components/Milestone/AddMilestone"
        ),
      // meta: { permission: "add-milestone", role: true }
    },
    // {
    //   path: "/invoice",
    //   name: "invoice",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "invoice" */ "@/components/Finance/Invoice"
    //     ),
    // },
    {
      path: "/role-management",
      name: "roles",
      component: () =>
        import(/* webpackChunkName: "roles" */ "@/components/Role/ListRoles"),
      meta: { permission: "role-management", role: true },
    },
    {
      path: "/roles-add",
      name: "AddRolesManual",
      component: () =>
        import(
          /* webpackChunkName: "roles" */ "@/components/Role/AddRolesManual"
        ),
    },
    {
      path: "/add-roles/:reqtype",
      name: "add-roles",
      component: () =>
        import(/* webpackChunkName: "roles" */ "@/components/Role/AddRoles"),
      //   meta: { permission: "add-roles", role: true }
    },
    {
      path: "/mapping/:id/:mappingType",
      name: "mapping",
      component: () =>
        import(
          /* webpackChunkName: "mapping" */ "@/components/Role/CreateMapping"
        ),
    },
    {
      path: "/user-management",
      name: "users",
      component: () =>
        import(/* webpackChunkName: "users" */ "@/components/User/UserList"),
      meta: { permission: "user-management", role: true },
    },
    {
      path: "/user-management/:id",
      name: "show-users",
      component: () =>
        import(
          /* webpackChunkName: "show-users" */ "@/components/User/ShowUser"
        ),
      meta: { permission: "user-management", role: true },
    },
    {
      path: "/add-users",
      name: "add-users",
      component: () =>
        import(/* webpackChunkName: "add-users" */ "@/components/User/AddUser"),
    },

    {
      path: "/edit-user/:id",
      name: "edit-user",
      component: () =>
        import(
          /* webpackChunkName: "edit-users" */ "@/components/User/EditUser"
        ),
    },
  ],
});

let routeSeq = [
  {
    route: "jobs",
    location: "jobs",
  },
  {
    route: "drivers",
    location: "drivers",
  },
  {
    route: "tender",
    location: "tender",
  },
  {
    route: "transporter",
    location: "transporter",
  },
  {
    route: "sales",
    location: "sales",
  },
  {
    route: "procurements",
    location: "procurements",
  },
  {
    route: "request-callback",
    location: "request-callback",
  },
  {
    route: "analytics",
    location: "analytics",
  },

  {
    route: "customers",
    location: "customers",
  },

  {
    route: "milestone",
    location: "milestone",
  },
  {
    route: "user-management",
    location: "user-management",
  },
  {
    route: "shipment-documents",
    location: "shipment-documents",
  },
  {
    route: "accounts",
    location: "accounts",
  },
  {
    route: "gps-devices",
    location: "ImeiDevices",
  },

  {
    route: "role-management",
    location: "role-management",
  },
  {
    route: "approvals",
    location: "approvals",
  },

  {
    route: "role-management",
    location: "role-management",
  },
];
// router.onError((error) => {
//   if (/loading chunk \d* failed./i.test(error.message)) {
//     window.location.reload();
//   }
// });
router.beforeEach((to, from, next) => {
  if (!navigator.onLine) {
    // this.loading = false;
    Swal.fire({
      title: "Oops!",
      text: "Your internet connection seems to be offline.Please try again.",
      type: "error",
      allowOutsideClick: false,
      confirmButtonColor: "orange",
      confirmButtonText: "Okay",
    }).then((result) => {
      if (result.value) {
      }
    });

    return;
  }

  // next();
  if (
    to.path !== "/auth" &&
    to.name !== "waybill-detail" &&
    to.name !== "waybill-detail-old" &&
    to.name !== "opeartion-sales" &&
    to.path !== "/inquiry-error" &&
    to.name !== "invoice-detail"
  ) {
    if (localStorage.getItem(StorageKeys.SessionKey) !== null) {
      if (to.meta.permission) {
        if (localStorage.getItem("permissions")) {
          let userPermissions = JSON.parse(
            localStorage.getItem("permissions")
          ).permissions;
          let roles = JSON.parse(localStorage.getItem("permissions")).roles;

          if (!userPermissions) {
            Swal.fire({
              title: "Oops!",
              text: "Seems like you don't have any permissions. Please contact administrator.",
              type: "error",
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              next(false);
            });
          } else if (!roles.length) {
            Swal.fire({
              title: "Oops!",
              text: "You don't have access to Oms. Kindly contact your admin",
              type: "error",
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              import("@/constants/constants.js").then(({ StorageKeys }) => {
                //Comman local storage
                localStorage.removeItem("webToken");
                localStorage.removeItem("activeMenu");
                localStorage.removeItem("permissions");
                localStorage.removeItem("isWebView");
                localStorage.removeItem("userId");
                localStorage.removeItem("user");
                localStorage.removeItem("role");
                localStorage.removeItem("cust-status");
                localStorage.removeItem("user_Id");
                localStorage.removeItem("email");
                localStorage.removeItem("unreadCount");
                localStorage.removeItem("workingCountries");
                localStorage.removeItem("changeTabListing");
                //Jobs section local storage
                localStorage.removeItem("paginationUrlJob");
                localStorage.removeItem("searchJob");
                localStorage.removeItem("searchJobDriver");
                localStorage.removeItem("searchJobType");
                localStorage.removeItem("searchJobDriverType");
                localStorage.removeItem("subRealBook");
                localStorage.removeItem("subJobType");
                localStorage.removeItem("filterType");
                localStorage.removeItem("subGeoType");
                localStorage.removeItem("subJobMode");
                localStorage.removeItem("jobStatus");
                localStorage.removeItem("jobTabStatus");
                localStorage.removeItem("JobstartdateFilter");
                localStorage.removeItem("JobenddateFilter");
                //Procurement section local storage
                localStorage.removeItem("subJobTypePro");
                localStorage.removeItem("ProtartdateFilter");
                localStorage.removeItem("ProenddateFilter");
                localStorage.removeItem("searchProcurement");
                localStorage.removeItem("searchProcurementType");
                localStorage.removeItem("searchProcurementDriver");
                localStorage.removeItem("searchProcurementDriverType");
                localStorage.removeItem("paginationUrlProcure");
                //Driver section local storage
                localStorage.removeItem("searchDriverPreferredLocation");
                localStorage.removeItem("searchDriverStatus");
                localStorage.removeItem("searchDriverType");
                localStorage.removeItem("searchDrivertext");
                localStorage.removeItem("DriverJobType");
                localStorage.removeItem("subTruckType");
                localStorage.removeItem("driverVerificationStatus");
                localStorage.removeItem("payloadDriver");
                //Transporter Section local storage
                localStorage.getItem("searchTransporter");
                //Assignment Section local storage
                localStorage.removeItem("paginationUrlAssignment");
                localStorage.removeItem("TabStatusAssign");
                //Advance Section local Storage
                localStorage.removeItem("paginationUrlAdvance");

                localStorage.removeItem(StorageKeys.SessionKey);
                next({
                  path: "/auth",
                  query: { redirect: to.fullPath },
                });
              });
              next(false);
            });
          } else {
            let roles = JSON.parse(localStorage.getItem("permissions")).roles;
            userPermissions = userPermissions.map((x) => x.operationName);
            roles = roles.map((x) => x.role.roleName.toLowerCase());

            let url = "";
            if (to.meta.role && roles.length) {
              if (roles.indexOf(to.meta.permission) == -1) {
                //TODO Default component
                if (localStorage.getItem("first-load") && roles) {
                  if (roles.length > 0) {
                    exit_loops: for (
                      let index = 0;
                      index < routeSeq.length;
                      index++
                    ) {
                      const ele = routeSeq[index];
                      for (let j = 0; j < roles.length; j++) {
                        const __role = roles[j];
                        if (__role == ele.route) {
                          url = ele.route;
                          break exit_loops;
                        }
                      }
                    }

                    localStorage.removeItem("first-load");
                    //   this.$router.replace(`/${roles[0].roleName}`);
                    next({
                      path: `/${url}`,
                    });
                  } else {
                    next({
                      path: `/`,
                    });
                  }
                }
                next(false);
              } else {
                next();
              }
            } else {
              if (userPermissions.indexOf(permission.value) == -1) {
                next(false);
              } else {
                next();
              }
            }
          }
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next({
        path: "/auth",
        query: { redirect: to.fullPath },
      });
    }
  } else if (
    to.name === "waybill-detail" ||
    to.name === "waybill-detail-old" ||
    to.name === "opeartion-sales" ||
    to.path === "/inquiry-error" ||
    to.name === "invoice-detail"
  ) {
    next();
  } else {
    next();
  }
});

export default router;
